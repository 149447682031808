// const tips = {
// 	tipsList: document.querySelector('.tips_listing'),
// 	listClick(e) {
// 		const clickedHeading = e.target.closest('h4');

// 		if(clickedHeading) {
// 			clickedHeading.closest('li').toggleAttribute('open');
// 		}
// 	},
// 	init() {
// 		this.tipsList.addEventListener('click', e => this.listClick(e));
// 		setTimeout(() => {
// 			document.querySelector('.tips_listing h4').click();
// 		}, 500);
// 	}
// };

// export const initTips = () => tips.init();


const tips = {
	init() {
		[].forEach.call(document.querySelectorAll(".tips_listing h4"), (el) => {
			el.addEventListener('click', (e) => {
				let list = e.currentTarget;
				list = list.parentElement;
				if(list.classList.contains('open')){
					list.classList.remove('open');
				}else{
					list.classList.add('open');
				}
			});
		});
		setTimeout(() => {
			document.querySelector('.tips_listing h4').click();
		}, 500);
	}
};

export const initTips = () => tips.init();