const mobileHeader = {
	toggleBtn: document.querySelector('#toggle_mobile_nav'),
	navContainer: document.querySelector('#main_nav'),
	btnClick() {
		this.toggleBtn.toggleAttribute('open');
		this.navContainer.toggleAttribute('open');
	}, 
	navClick(e) {
		if(e.target.id === 'main_nav') {
			this.toggleBtn.removeAttribute('open');
			this.navContainer.removeAttribute('open');
		}
	},
	init() {
		this.toggleBtn.addEventListener('click', () => this.btnClick());
		this.navContainer.addEventListener('click', e => this.navClick(e));
	}
};

export const initMobileHeader = () => mobileHeader.init();