
import { initMobileHeader } from './header.js';
import { initHomeMap } from './home.js';
import { filters, facilityCaro, facilityButtons, facilityMap, facilityTopToggle } from './facility.js';
import { initTips } from './tips.js';
import { initReviewsCaro } from './reviews';
import { contactSubmit } from './contact.js';
import { maps } from './nearby_map.js';

initMobileHeader();

filters.init();
facilityCaro.init();
facilityButtons.init();
facilityMap.init();
facilityTopToggle.init();
maps.init();

if(document.querySelector('.tips_listing')) {
	initTips();
}
if (document.getElementById('contact_form')) {
	contactSubmit();
}

if(document.querySelector('#reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#home_map')) {
	initHomeMap();
}
 
