import { AutomatitCarousel } from './automatit_carousel.m';

const reviewsCaro = {
	caro: document.querySelector('#reviews_caro'),
	init: function() {
		window.reviewsCaro = AutomatitCarousel({
			element: this.caro,
			useChevrons: false
		});

		document.querySelector('#reviews_caro').addEventListener('click', e => {
			const clickedButton = e.target.closest('button');
	
			if(clickedButton.classList.contains('close_overlay')) {
				clickedButton.closest('.card_full_text').removeAttribute('show');
			} else {
				if(clickedButton) {
					const theCard = e.target.closest('.review_card');
					theCard.querySelector('.card_full_text').setAttribute('show', true);
				}
			}
		});
	}
};
export const initReviewsCaro = () => reviewsCaro.init();