/* eslint-disable no-undef */

import {
	AutomatitCarousel
} from './automatit_carousel.m';
import {
	getMapsHelper
} from './util/mapsHelper';

export const filters = (function(){
	let my = {};

	function _filterUnits(e){
		var unitShown = 0;
		const noUnits = document.querySelector('#no_units');
		document.querySelector("#facility_rates_filter .active").classList.remove('active');
		e.currentTarget.classList.add('active');

		let size = e.currentTarget.dataset.size;
		[].forEach.call(document.querySelectorAll('#facility_rates .unit_row'), (row) => {
			row.style.display = 'none';
			if(size === "all" || row.dataset.props.includes(size)){
				row.style.display = 'flex';
				unitShown++;

			}
			if(unitShown === 0){
				noUnits.style.display = 'block';
			}else {
				noUnits.style.display = 'none';
			}
		});
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_rates_filter .rates_filter'), (filter) => {
			filter.addEventListener('click', _filterUnits);
		});
	}

	my.init = function(){
		if(document.getElementById('facility_rates_filter')){
			_assignListeners();
		}
	};

	return my;
})();

export const facilityCaro = (function(){
	let my = {};

	my.init = function(){
		if(document.getElementById('facility_caro')){
			let interval = window.slide_images.length > 1 ? 6000 : 0;
			let chevrons = window.slide_images.length > 1 ? true : false;
			const options = {
				element: document.getElementById('facility_caro'),
				speed: 600,
				images: window.slide_images,
				intervalTiming: interval,
				imagesAsBackgrounds: true,
				useChevrons: chevrons
			};
			AutomatitCarousel(options);
		}
	};

	return my;
})();

export const facilityButtons = (function(){
	let my = {};

	function _toggleMap(e){
		document.querySelector(".facility_controls_btns.active").classList.remove('active');
		e.currentTarget.classList.add('active');

		if(e.currentTarget.dataset.btn === 'map'){
			document.getElementById('facility_map').classList.add('active');
		}else{
			document.getElementById('facility_map').classList.remove('active');
		}
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('.facility_controls_btns'), (btn) => {
			btn.addEventListener('click', _toggleMap);
		});
	}

	my.init = function(){
		if(document.getElementById('facility_top_controls')){
			_assignListeners();
		}
	};

	return my;
})();

export const facilityMap = (function(){
	let my = {};

	my.init = function(){
		if(document.getElementById('facility_map')){

			const mapHelper = getMapsHelper();

			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: document.getElementById('facility_map'),
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                        <span class="map_pin"><img src="/dist/images/icons/ic_map_pin.svg" alt=""></span>
                        `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});

		}
	};

	return my;
})();

export const facilityTopToggle = (function(){
	let my = {};

	my.init = function(){
		if (document.getElementById('facility_top_toggle')){
			document.getElementById('facility_top_toggle').addEventListener('click', () => {
				document.getElementById('facility_top_toggle').classList.toggle('active');
				document.getElementById('facility_top').classList.toggle('active');
			});
		}
	};

	return my;
})();